import { JsonProperty } from 'json-object-mapper';
import { OrderModule } from '../module.enum';
import { BusinessServiceTypeResponseModel } from '@adminpanels/api-clients/orderadmin-api-client';

const names = new Map<string, OrderModule>([
  ['take away', OrderModule.TakeAway],
  ['inhouse', OrderModule.Inhouse],
  ['delivery', OrderModule.Delivery],
  ['hotel service', OrderModule.HotelService],
]);

export class Module extends BusinessServiceTypeResponseModel {
  @JsonProperty() public title: string;

  constructor(data?: BusinessServiceTypeResponseModel) {
    super();
    this.title = '';
    if (data) {
      Object.assign(this, data);
    }
  }

  public get sanitizedName(): string {
    return this.name ? this.name.toLowerCase() : '';
  }

  public get moduleName(): OrderModule | undefined {
    return names.get(this.sanitizedName);
  }

  public get isTakeAway(): boolean {
    return this.moduleName === OrderModule.TakeAway;
  }

  public get isInhouse(): boolean {
    return this.moduleName === OrderModule.Inhouse;
  }

  public get isHotelService(): boolean {
    return this.moduleName === OrderModule.HotelService;
  }

  public get isDelivery(): boolean {
    return this.moduleName === OrderModule.Delivery;
  }

  public get canManage(): boolean {
    return (
      (this.isTakeAway && this.isEnabled) ||
      (this.isInhouse && this.isEnabled) ||
      (this.isHotelService && this.isEnabled) ||
      (this.isDelivery && this.isEnabled) ||
      false
    );
  }
}
