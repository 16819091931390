import { Injectable } from '@angular/core';
import BusinessState from './business.state';

@Injectable({ providedIn: 'root' })
class BusinessFacade {
  public readonly getCountries = this._businessState.getCountries.bind(
    this._businessState,
  );
  public readonly refreshStore = this._businessState.refreshStore.bind(
    this._businessState,
  );

  public readonly getCountryById = this._businessState.getCountryById.bind(
    this._businessState,
  );

  public readonly getBusinessTypes = this._businessState.getBusinessTypes.bind(
    this._businessState,
  );

  public readonly createBusiness = this._businessState.createBusiness.bind(
    this._businessState,
  );

  public fetchMenuProducts = this._businessState.fetchMenuProducts.bind(
    this._businessState,
  );

  public readonly getSubcategories = this._businessState.getSubcategories.bind(
    this._businessState,
  );

  public readonly setBusinessId = this._businessState.setBusinessId.bind(
    this._businessState,
  );

  public readonly setUserId = this._businessState.setUserId.bind(
    this._businessState,
  );

  public getCurrentUserId = this._businessState.getCurrentUserId.bind(
    this._businessState,
  );

  public getCurrentBusinessId = this._businessState.getCurrentBusinessId.bind(
    this._businessState,
  );

  public clearUserAndBusinessId =
    this._businessState.clearUserAndBusinessId.bind(this._businessState);

  public readonly countries$ = this._businessState.countries$;
  public readonly businessId$ = this._businessState.businessId$;
  public menuProducts$ = this._businessState.menuProducts$;
  public readonly userId$ = this._businessState.userId$;
  public readonly businessTypes$ = this._businessState.businessTypes$;

  constructor(private readonly _businessState: BusinessState) {}
}

export default BusinessFacade;
