import { Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import BusinessFacade from '../business.facade';
import { BusinessSubcategory } from '@adminpanels/shared-ui';
import {
  AddressRequestModel,
  CreateBusinessRequestModel,
} from '@adminpanels/api-clients/orderadmin-api-client';

@Component({
  selector: 'orderadmin-frontend-create-business',
  templateUrl: './create-business.component.html',
  styleUrls: ['./create-business.component.scss'],
})
export class CreateBusinessComponent implements OnInit {
  passwordPattern =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/;

  public readonly createBusinessForm = new UntypedFormGroup({
    businessName: new FormControl('', Validators.required),
    businessType: new FormControl('', Validators.required),
    businessTypeCategoryId: new FormControl(null),
    streetName: new FormControl('', Validators.required),
    city: new FormControl('', Validators.required),
    country: new FormControl('', Validators.required),
    ownerFirstName: new FormControl('', Validators.required),
    ownerLastName: new FormControl('', Validators.required),
    ownerPassword: new FormControl('', Validators.required),
    postalCode: new FormControl('', [
      Validators.required,
      Validators.maxLength(6),
    ]),
    ownerEmail: new FormControl('', [Validators.required, Validators.email]),
  });

  constructor(public readonly businessFacade: BusinessFacade) {}

  public ngOnInit(): void {
    this.businessFacade.getCountries();
    this.businessFacade.getBusinessTypes();
  }

  public get disableButton(): boolean {
    return this.createBusinessForm.invalid || this._subcategoriesIsInvalid;
  }

  public isInvalid(controlName: string): boolean {
    return this.createBusinessForm.controls[controlName].invalid;
  }

  public get subcategories(): BusinessSubcategory[] | undefined {
    return this.businessFacade.getSubcategories(
      this.createBusinessForm.controls['businessType'].value,
    );
  }

  public onSubmit(): void {
    this.businessFacade.createBusiness({
      businessTypeId: Number(
        this.createBusinessForm.controls['businessType'].value,
      ),
      businessTypeCategoryId: this._subcategoriesIsInvalid
        ? null
        : this.createBusinessForm.controls['businessTypeCategoryId'].value,
      businessName: this.createBusinessForm.controls['businessName'].value,
      address: {
        street: this.createBusinessForm.controls['streetName'].value,
        postalCode: this.createBusinessForm.controls['postalCode'].value,
        city: this.createBusinessForm.controls['city'].value,
        countryId: Number(this.createBusinessForm.controls['country'].value),
      } as AddressRequestModel,
      firstName: this.createBusinessForm.controls['ownerFirstName'].value,
      lastName: this.createBusinessForm.controls['ownerLastName'].value,
      email: this.createBusinessForm.controls['ownerEmail'].value,
      password: this.createBusinessForm.controls['ownerPassword'].value,
    } as CreateBusinessRequestModel);
  }

  private get _subcategoriesIsInvalid(): boolean {
    return (
      Boolean(this.subcategories?.length) &&
      !this.createBusinessForm.controls['businessTypeCategoryId'].value
    );
  }
}
