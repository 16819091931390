<div class="top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
  <button
    type="button"
    class="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
  >
    <span class="sr-only">Open sidebar</span>
    <svg
      class="h-6 w-6"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      inert
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M4 6h16M4 12h16M4 18h7"
      />
    </svg>
  </button>
  <div *ngIf="companyLogo" class="p-4 flex justify-center font-medium">
    <img [src]="companyLogo" width="40" alt="" />

    <div class="title ml-10" *ngIf="isDashboard()">
      <h2 style="padding-bottom: 5px">{{ title }}</h2>
    </div>
  </div>

  <div *ngIf="isWaiterApp" class="p-4 flex justify-center font-medium">
    <p class="text-xl text-[#253858] pl-4">
      {{ 'waiterApp.selectedTableLabel' | translate }}:
      <span class="selected-table">{{ getSelectedTableFromWaiterApp }}</span>
    </p>
  </div>
  <div class="flex-1 px-4 flex justify-between">
    <div class="flex-1 flex">
      <form class="w-full flex md:ml-0" action="#" method="GET">
        <label for="search-field" class="sr-only">Search</label>
        <div
          class="relative w-full text-gray-400 focus-within:text-gray-600"
        ></div>
      </form>
    </div>
    <div class="ml-4 flex items-center md:ml-6"></div>
    <div class="ml-4 flex items-center md:ml-6">
      <button
        mat-icon-button
        *ngIf="showSettingsCog"
        (click)="toggleSideBarSettings()"
        class="bg-white rounded-full outline-button m-1 inbtn"
      >
        <i
          class="far-sharp fa-light fa-gear"
          style="vertical-align: unset !important"
        ></i>
      </button>

      <!-- Profile dropdown -->
      <div class="ml-3 relative">
        <div>
          <button
            type="button"
            class="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            id="user-menu-button"
            [attr.aria-expanded]="false"
            [attr.aria-haspopup]="true"
            (click)="toggleShow()"
          >
            <span class="sr-only">Open user menu</span>
            <img
              class="h-8 w-8 rounded-full"
              src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              alt=""
            />
          </button>
        </div>

        <div
          class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none cursor-pointer"
          role="menu"
          [attr.aria-orientation]="'vertical'"
          [attr.aria-labelledby]="'user-menu-button'"
          tabindex="-1"
          *ngIf="isShown"
        >
          <a
            class="block px-4 py-2 text-sm text-gray-700 hover:text-primary"
            role="menuitem"
            tabindex="-1"
            id="user-menu-item-2"
            (click)="onLogout()"
            >Sign out</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
