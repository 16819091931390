import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BottomNavItem, NavItem } from '@adminpanels/shared-ui';
import {
  ACTIVE_CLASSES,
  INACTIVE_CLASSES,
  XlSizeScreen,
} from './side-nav.data';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent {
  @Input() public navItems: NavItem[] = [];
  @Input() public bottomNavItems: BottomNavItem[] = [];
  @Output() dataEvent = new EventEmitter<string>();

  private activeBottomNavItem = '';
  isDrawerExpanded = true;

  constructor(private readonly _router: Router) {
    const storedValue = localStorage.getItem('isDrawerExpanded');
    if (storedValue == null && window.innerWidth < XlSizeScreen) {
      this.isDrawerExpanded = false;
    } else {
      this.isDrawerExpanded = storedValue ? JSON.parse(storedValue) : true;
    }
  }

  public navItemClasses(
    link: string,
    isOpenInNewTab: boolean = false,
  ): string[] {
    if (isOpenInNewTab) {
      return [...ACTIVE_CLASSES, 'special-link'];
    }

    return this._isActive(link) ? ACTIVE_CLASSES : INACTIVE_CLASSES;
  }

  public isActive(item: BottomNavItem): boolean {
    return item.key === this.activeBottomNavItem;
  }

  public goToLink(item: NavItem): void {
    if (item.key === 'waiterapp-url') {
      localStorage.setItem('selectedTable', '{}');
    }

    if (item.openNewTab) {
      window.open(item.link, '_blank');
    } else {
      this._router.navigate([item.link]);
    }
  }

  public setActive(item: BottomNavItem): void {
    if (item.key) {
      this.activeBottomNavItem = item.key;
    }
  }

  public getSvgUrl(iconName: string): string {
    return `../../assets/icons/${iconName}`;
  }

  private _isActive(link: string): boolean {
    return (
      this._router.url?.replace('/', '')?.toLowerCase() === link?.toLowerCase()
    );
  }
  toggleDrawer() {
    this.isDrawerExpanded = !this.isDrawerExpanded;
    localStorage.setItem('isDrawerExpanded', this.isDrawerExpanded.toString());
    this.dataEvent.emit(this.isDrawerExpanded ? 'expanded' : 'collapsed');
  }
}
