<h2 mat-dialog-title>{{ data.title }}</h2>
<div mat-dialog-content>
  {{ data.text }}
</div>
<br />
<br />
<div class="prompt-buttons" mat-dialog-actions>
  <button mat-button mat-dialog-close class="close-btn">
    {{ 'global.cancelButton' | translate }}
  </button>
  <button mat-raised-button color="primary" (click)="proceed()">
    {{ data.ok || 'Weiter' }}
  </button>
</div>
