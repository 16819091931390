import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RoleFacade } from '../facades/role.facade';
import { map } from 'rxjs/operators';
import { Role } from '../enums/role.enum';

@Injectable({ providedIn: 'root' })
export class AdminGuardService implements CanActivate {
  constructor(
    private readonly _roleFacade: RoleFacade,
    private readonly _router: Router,
  ) {}

  public canActivate(): Observable<boolean> {
    return this._roleFacade.roles$.pipe(
      map((roles) => {
        const isAdmin = roles.includes(Role.JAMATU_ADMIN);

        if (!isAdmin) {
          this._router.navigate(['no-access']).then();
        }

        return isAdmin;
      }),
    );
  }
}
