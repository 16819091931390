import { Component, ViewChild } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { ChatSupportDialogComponent } from "./chat-support-dialog/chat-support-dialog.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: 'orderadmin-frontend-sidebar-help',
  templateUrl: './sidebar-help.component.html',
  styleUrls: ['./sidebar-help.component.scss'],
})
export class SidebarHelpComponent {
  @ViewChild('sideDrawer', { static: true }) public helpSidebar!: MatSidenav;

  constructor(private dialog: MatDialog) {}

  closeSideDrawer(): void {
    this.helpSidebar.close();
  }

  toggle(): void {
    this.helpSidebar.toggle();
  }

  redirectToDocumentation(): void {
    window.open('https://help.jamatu.com', '_blank');
  }

  openChatWithSupport(): void {
    this.dialog.open(ChatSupportDialogComponent, {
      width: '500px',
      hasBackdrop: true,
      disableClose: false,
    });
  }
}
