<mat-sidenav-container style="overflow-x: hidden">
  <div>
    <app-mobile-nav *ngIf="false" [navItems]="navItems"></app-mobile-nav>
    <app-side-nav
      *ngIf="showMenu"
      [navItems]="navItems"
      [bottomNavItems]="bottomNavItems"
      (dataEvent)="onDrawerToggle($event)"
    ></app-side-nav>

    <div
      class="flex flex-col flex-1"
      [ngClass]="{ 'md:pl-64': showMenu }"
      [ngClass]="{
        'ml-64': isDrawerExpanded && showMenu,
        'ml-16': !isDrawerExpanded && showMenu,
      }"
      class="relative flex-grow bg-gray-100 transition-all duration-300 ease-in-out z-10 container-position"
    >
      <sidebar-settings *ngIf="showDrawer" #drawer></sidebar-settings>

      <orderadmin-frontend-sidebar-help #helpSidebar></orderadmin-frontend-sidebar-help>

      <app-header
        [isDrawerExpanded]="isDrawerExpanded"
        (showDrawer)="handleDrawer()"
        (showHelpSidebar)="handleHelpSidebar()"
      ></app-header>
      <app-main [pageTitle]="titleService.getTitle()">
        <ng-content></ng-content>
      </app-main>
    </div>
  </div>
</mat-sidenav-container>
