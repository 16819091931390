import { OrderModule } from '../modules/main/pages/modules/module.enum';

export const serviceTypesData = {
  [OrderModule.TakeAway]: {
    name: 'Take Away',
    nameTranslated: 'Abholen',
  },
  [OrderModule.Delivery]: {
    name: 'Delivery',
    nameTranslated: 'Lieferung',
  },
  [OrderModule.Inhouse]: {
    name: 'Inhouse',
    nameTranslated: 'Inhouse',
  },
  [OrderModule.HotelService]: {
    name: 'Hotel Service',
    nameTranslated: 'Zimmerservice',
  },
};
