import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Module } from './models/module.model';
import {
  AreaClient,
  AreaResponse,
  BusinessServiceTypeRequestModel,
  BusinessServiceTypeResponseModel,
  BusinessServiceTypesClient,
  DeliveryZone,
  DeliveryZonesClient,
  GetAllAreasResponse,
  MinimumOrderValueDto,
  MinimumOrderValuesClient,
  OrderingTimeRequest,
  OrderingTimeResponse,
  OrderingTimesClient,
  PreparationTimeRequest,
  PreparationTimeResponse,
  PreparationTimesClient,
  UpdateDeliveryZoneRequest,
  UpdateMinimumOrderValueRequest,
} from '@adminpanels/api-clients/orderadmin-api-client';
import { DeliveryZoneViewModel } from './models/delivery-zone.model';
import { MinimumOrderValueViewModel } from './models/minimum-order-value.model';

@Injectable({ providedIn: 'root' })
class ModuleService {
  constructor(
    private readonly _businessServiceTypesClient: BusinessServiceTypesClient,
    private readonly _orderingTimesClient: OrderingTimesClient,
    private readonly _preparationTimesClient: PreparationTimesClient,
    private readonly _deliveryZonesClient: DeliveryZonesClient,
    private readonly _minimumOrderValuesClient: MinimumOrderValuesClient,
    private readonly _areaClient: AreaClient,
  ) {}

  public fetchModules(businessId: number): Observable<Module[]> {
    return this._businessServiceTypesClient
      .get(businessId)
      .pipe(
        map((response: BusinessServiceTypeResponseModel[]) =>
          response.map(
            (item: BusinessServiceTypeResponseModel) => new Module(item),
          ),
        ),
      );
  }

  public updateOrderingTimes(request: OrderingTimeRequest): Observable<void> {
    return this._orderingTimesClient.post(request);
  }

  public updatePreparationTimes(
    params: PreparationTimeRequest,
  ): Observable<void> {
    return this._preparationTimesClient.post(params);
  }

  public updateModule(
    businessId: number,
    requestModel: BusinessServiceTypeRequestModel,
  ): Observable<void> {
    return this._businessServiceTypesClient.put(requestModel, businessId);
  }

  public fetchOrderingTimes(
    businessId: number,
    serviceType: string,
  ): Observable<OrderingTimeResponse[]> {
    return this._orderingTimesClient.get(businessId, serviceType);
  }

  public fetchPreparationTimes(
    businessId: number,
    serviceType: string,
  ): Observable<PreparationTimeResponse[]> {
    return this._preparationTimesClient.get(businessId, serviceType);
  }

  public fetchDeliveryZones(
    businessId: number,
  ): Observable<DeliveryZoneViewModel[]> {
    return this._deliveryZonesClient
      .get(businessId)
      .pipe(
        map((deliveryZones: DeliveryZone[]) =>
          deliveryZones.map(
            (dz: DeliveryZone) => new DeliveryZoneViewModel(dz),
          ),
        ),
      );
  }

  public addDeliveryZone(
    request: UpdateDeliveryZoneRequest,
  ): Observable<DeliveryZoneViewModel> {
    return this._deliveryZonesClient
      .create(request)
      .pipe(
        map(
          (deliveryZone: DeliveryZone) =>
            new DeliveryZoneViewModel(deliveryZone),
        ),
      );
  }

  public updateDeliveryZone(
    params: UpdateDeliveryZoneRequest,
  ): Observable<DeliveryZoneViewModel> {
    return this._deliveryZonesClient
      .update(params)
      .pipe(
        map(
          (deliveryZone: DeliveryZone) =>
            new DeliveryZoneViewModel(deliveryZone),
        ),
      );
  }

  public deleteDeliveryZone(
    deliveryZoneId: number,
  ): Observable<DeliveryZoneViewModel> {
    return this._deliveryZonesClient
      .delete(deliveryZoneId)
      .pipe(
        map(
          (deliveryZone: DeliveryZone) =>
            new DeliveryZoneViewModel(deliveryZone),
        ),
      );
  }

  public fetchMinimumOrderValue(
    businessId: number,
    serviceTypeName: string,
  ): Observable<MinimumOrderValueViewModel> {
    return this._minimumOrderValuesClient
      .getMinimumOrderValue(businessId, serviceTypeName)
      .pipe(
        map((dto: MinimumOrderValueDto) => new MinimumOrderValueViewModel(dto)),
      );
  }

  public addMinimumOrderValue(
    request: UpdateMinimumOrderValueRequest,
  ): Observable<MinimumOrderValueViewModel> {
    return this._minimumOrderValuesClient
      .create(request)
      .pipe(
        map((dto: MinimumOrderValueDto) => new MinimumOrderValueViewModel(dto)),
      );
  }

  public updateMinimumOrderValue(
    request: UpdateMinimumOrderValueRequest,
  ): Observable<void> {
    return this._minimumOrderValuesClient.update(request);
  }

  public deleteMinimumOrderValue(
    minimumOrderValueId: number,
  ): Observable<void> {
    return this._minimumOrderValuesClient.delete(minimumOrderValueId);
  }

  public fetchAreas(
    businessId: number,
    serviceType: string,
    currentPage?: number,
    pageSize?: number,
  ): Observable<GetAllAreasResponse> {
    return this._areaClient.get(businessId, serviceType, currentPage, pageSize);
  }

  public fetchUnassignedAreas(
    businessId: number,
    serviceType: string,
  ): Observable<AreaResponse[]> {
    return this._areaClient.getUnassigned(businessId, serviceType);
  }

  public updateArea(request: AreaResponse): Observable<AreaResponse> {
    return this._areaClient.update(request);
  }

  public addArea(request: AreaResponse): Observable<AreaResponse> {
    return this._areaClient.create(request);
  }
}

export default ModuleService;
