import { Component, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@auth0/auth0-angular';
import BusinessFacade from '../../../../../../../../apps/orderadmin/src/app/modules/business/business.facade';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  public isShown = false;
  title!: string;
  private _cachedUser: any;
  @Output() showDrawer: EventEmitter<any> = new EventEmitter();
  constructor(
    private _translate: TranslateService,
    public _authService: AuthService,
    private _businessFacade: BusinessFacade,
  ) {
    this._authService.isAuthenticated$.subscribe((isAuthenticated) => {
      if (!isAuthenticated) {
        this._authService.loginWithRedirect();
      }
    });
    this._translate
      .get('global.orderDashboardLabel')
      .subscribe((translation) => {
        this.title = translation;
      });
  }

  public switchLang(lang: string): void {
    this._translate.use(lang);
  }

  public onLogout(): void {
    this._authService.logout({
      logoutParams: { returnTo: document.location.origin },
    });
    sessionStorage.clear();
    this._businessFacade.clearUserAndBusinessId();
    localStorage.removeItem('user'); // TODO: needs to be handled with a state class or so.
  }

  public get showLogo(): boolean {
    const url = window.location.href;
    return url.includes('orders-dashboard');
  }

  public get showSettingsCog(): boolean {
    const url = window.location.href;
    return url.includes('orders-dashboard');
  }

  private getUserFromLocalStorage(): any {
    if (!this._cachedUser) {
      this._cachedUser = JSON.parse(localStorage.getItem('user') || '{}');
    }
    return this._cachedUser;
  }

  public get companyLogo() {
    const user = this.getUserFromLocalStorage();
    const url = window.location.href;
    if (url.includes('waiterapp') || url.includes('orders-dashboard')) {
      return user?.logoImageUrl;
    }
    return false;
  }

  public get companyName() {
    return this.getUserFromLocalStorage().name;
  }

  public get isWaiterApp() {
    const url = window.location.href;
    return url.includes('waiterapp');
  }

  public get getSelectedTableFromWaiterApp() {
    let table = JSON.parse(localStorage.getItem('selectedTable') || '{}');
    var noTableText = '';
    this._translate.get('waiterApp.noTableSelected').subscribe((text) => {
      noTableText = text;
    });

    return table?.number && table?.area
      ? `#${table?.number} - ${table?.area}`
      : noTableText;
  }

  public toggleShow(): void {
    this.isShown = !this.isShown;
  }
  toggleSideBarSettings() {
    this.showDrawer.emit();
  }

  isDashboard() {
    const url = window.location.href;
    return url.includes('orders-dashboard');
  }
}
