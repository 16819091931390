import { Injectable } from '@angular/core';
import { map, Observable, shareReplay, take } from 'rxjs';
import { ObjectMapper } from 'json-object-mapper';
import { BusinessType } from '@adminpanels/shared-ui';
import {
  BusinessesClient,
  BusinessTypesClient, BusinessUserResponse,
  CountriesClient,
  Country,
  CreateBusinessRequestModel,
  GetProductsForBusinessByCategoriesResponse,
  ProductsClient
} from "@adminpanels/api-clients/orderadmin-api-client";

@Injectable({ providedIn: 'root' })
class BusinessService {
  constructor(
    private readonly countriesClient: CountriesClient,
    private readonly businessTypesClient: BusinessTypesClient,
    private readonly businessesClient: BusinessesClient,
    private readonly productsClient: ProductsClient,
  ) {}

  public getCountries(): Observable<Country[]> {
    return this.countriesClient.getAll().pipe(
      shareReplay(1),
      map((countries) => {
        const isHighlighted = countries
          .filter((country) => country.isHighlighted)
          .reverse();
        if (isHighlighted.length > 0) {
          isHighlighted[isHighlighted.length - 1].isHighlighted = true;
        }
        const isNotHighlighted = countries.filter(
          (country) => !country.isHighlighted,
        );

        return ObjectMapper.deserializeArray(Country, [
          ...isHighlighted,
          ...isNotHighlighted,
        ]);
      }),
    );
  }

  public getBusinessTypes(): Observable<BusinessType[]> {
    return this.businessTypesClient.getAll().pipe(
      shareReplay(1),
      map((businessTypes) =>
        ObjectMapper.deserializeArray(BusinessType, businessTypes),
      ),
    );
  }

  public getBusinessId(businessUserId: string): Observable<BusinessUserResponse> {
    return this.businessesClient.getBusinessUser(businessUserId);
  }

  public createBusiness(
    params: CreateBusinessRequestModel,
  ): Observable<number> {
    return this.businessesClient.createBusiness(params).pipe(
      shareReplay(1),
      map((response) => response.businessId as number),
    );
  }

  public fetchMenuProducts({
    businessId,
    menuId,
  }: {
    businessId: number;
    menuId: number;
  }): Observable<GetProductsForBusinessByCategoriesResponse> {
    return this.productsClient
      .getProductsForBusinessByCategories(businessId, menuId)
      .pipe(
        take(1),
        shareReplay(1),
        map((data) => data),
      );
  }
}

export default BusinessService;
