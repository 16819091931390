<p class="custom__label">{{ label }}</p>

<div [ngSwitch]="type" class="custom__container">
  <input
    class="custom__input"
    *ngSwitchCase="'input'"
    type="text"
    [readonly]="isDisabled"
    [formControl]="control"
    required
  />

  <orderadmin-frontend-avatar
    *ngSwitchCase="'avatar'"
    class="custom__avatar"
  ></orderadmin-frontend-avatar>

  <mat-select
    class="custom__select"
    placeholder="Select"
    *ngSwitchCase="'select'"
    [formControl]="control"
  >
    <mat-option *ngFor="let current of data" [value]="current">
      {{ current.name }}
    </mat-option>
  </mat-select>

  <mat-error class="custom__error" *ngIf="control?.touched && control?.invalid">
    Invalid value
  </mat-error>
</div>
