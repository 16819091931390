import { MenuDto } from '@adminpanels/api-clients/orderadmin-api-client';
import { Injectable } from '@angular/core';
import { Area } from 'apps/orderadmin/src/app/modules/main/pages/modules/models/area.model';
import { BehaviorSubject } from 'rxjs';

export const SIDEBAR_AREAS_STORAGE = 'sidebarAreas';
export const SIDEBAR_MENUS_STORAGE = 'sidebarMenus';

@Injectable({ providedIn: 'root' })
export class SidebarSettingsService {
  private dataAreaSubject = new BehaviorSubject<Area[]>([]);
  $dataArea = this.dataAreaSubject.asObservable();

  private dataMenuSubject = new BehaviorSubject<MenuDto[]>([]);
  $dataMenu = this.dataMenuSubject.asObservable();

  setAreaData(areas: Area[]): void {
    localStorage.setItem(SIDEBAR_AREAS_STORAGE, JSON.stringify(areas));
    this.dataAreaSubject.next(areas);
  }

  setMenusData(menus: MenuDto[]): void {
    localStorage.setItem(SIDEBAR_MENUS_STORAGE, JSON.stringify(menus));
    this.dataMenuSubject.next(menus);
  }
}
