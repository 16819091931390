import { Component, Input, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BottomNavItem, NavItem } from '@adminpanels/shared-ui';
import { Router } from '@angular/router';
import { MatDrawer } from '@angular/material/sidenav';
import { XlSizeScreen } from './components/side-nav/side-nav.data';
import { SidebarHelpComponent } from "../../component/sidebar-help/sidebar-help.component";

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  @Input() public navItems: NavItem[] = [];
  @Input() public bottomNavItems: BottomNavItem[] = [];
  showDrawer = false;
  showHelpSidebar = true;
  drawer!: MatDrawer;
  @ViewChild('drawer', { static: false }) set setDrawer(ref: MatDrawer) {
    this.drawer = ref;
  }

  helpSidebar!: SidebarHelpComponent; // Reference to the Help Sidebar

  @ViewChild('helpSidebar', { static: false }) set setHelpSidebar(
    ref: SidebarHelpComponent
  ) {
    this.helpSidebar = ref;
  }

  isDrawerExpanded: boolean = false;
  public showMenu = true;
  constructor(
    public readonly titleService: Title,
    private router: Router,
  ) {
    if (
      this.router.url.includes('/orders-dashboard') ||
      this.router.url.includes('/waiterapp')
    ) {
      this.showMenu = false;
    }
    this.showDrawerDashboard();

    const storedValue = localStorage.getItem('isDrawerExpanded');
    if (storedValue == null && window.innerWidth < XlSizeScreen) {
      this.isDrawerExpanded = false;
    } else {
      this.isDrawerExpanded = storedValue ? JSON.parse(storedValue) : true;
    }
  }

  handleDrawer() {
    this.drawer?.toggle();
  }

  handleHelpSidebar() {
    this.showHelpSidebar = !this.showHelpSidebar;
    this.helpSidebar?.toggle();
  }
  showDrawerDashboard() {
    if (this.router.url.includes('/orders-dashboard')) {
      this.showDrawer = true;
    }
  }

  onDrawerToggle(state: string): void {
    this.isDrawerExpanded = state === 'expanded';
  }
}
