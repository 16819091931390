import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../apps/orderadmin/src/environments/environment';
import {
  BusinessConfiguration,
  BusinessConfigurationClient,
} from '@adminpanels/api-clients/orderadmin-api-client';

@Injectable({
  providedIn: 'root',
})
export class BusinessConfigurationService {
  private readonly apiUrl = `${environment.API_URL}/BusinessConfiguration`;

  constructor(
    private readonly http: HttpClient,
    private readonly _businessConfigurationClient: BusinessConfigurationClient,
  ) {}

  public getConfigurationByBusinessId(
    businessId: number,
  ): Observable<BusinessConfiguration> {
    return this._businessConfigurationClient.getConfigurationByBusinessId(
      businessId,
    );
  }

  public saveOrUpdateConfiguration(
    businessConfiguration: BusinessConfiguration,
  ): Observable<void> {
    return this._businessConfigurationClient.saveOrUpdateConfiguration(
      businessConfiguration,
    );
  }
}
