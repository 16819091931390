import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RoleFacade } from '@adminpanels/shared-ui';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
} from '@angular/router';
import { distinctUntilChanged, filter, Subject } from 'rxjs';
import BusinessFacade from './modules/business/business.facade';
import { AuthService } from '@auth0/auth0-angular';
import BusinessService from './modules/business/business.service';
import { fullSessionTracker } from 'fullsession';
import {
  BusinessesClient,
  BusinessUserResponse,
} from '@adminpanels/api-clients/orderadmin-api-client';
import { Roles } from './modules/main/main.data';

@Component({
  selector: 'orderadmin-frontend-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit {
  private readonly _destroy$ = new Subject();

  constructor(
    private readonly _translate: TranslateService,
    private readonly _roleFacade: RoleFacade,
    private readonly _businessFacade: BusinessFacade,
    private readonly _businessService: BusinessService,
    private readonly _auth0: AuthService,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _businessClient: BusinessesClient,
  ) {}

  ngOnInit(): void {
    fullSessionTracker.initialize('cus_m3irwwbhjik');
    this.initializeBusinessAndUser();
  }

  private handleTokenClaims(token: any): void {
    if (token) {
      const roles =
        token['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
      const lowercaseRoles = roles.map((r: string) => r.toLowerCase());
      this._roleFacade.setRoles(lowercaseRoles);
    }
  }

  private initializeWithData(businessUserId: string): void {
    this._businessFacade.refreshStore();
    // this._translate.addLangs(['en', 'de']);
    // this._translate.setDefaultLang('en');

    this.handleRouteQueryParams(businessUserId);
  }

  private handleRouteQueryParams(businessUserId: string): void {
    const snapshot: ActivatedRouteSnapshot = this._route.snapshot;
    const paramMap = snapshot.queryParams;
    this.processQueryParams(paramMap, businessUserId);
  }

  private processQueryParams(paramMap: any, businessUserId: string): void {
    const redirect = paramMap['redirect'];
    const businessId = paramMap['businessId'];
    const userId = paramMap['userId'];

    const businessIdStore = this._businessFacade.getCurrentBusinessId();
    const userIdStore =
      this._businessFacade.getCurrentUserId() || businessUserId;
    this._roleFacade.roles$.subscribe((roles: any) => {
      if (roles.length == 0 && (businessIdStore || businessId)) {
        this.checkAndUpdateUserIfEmailVerified(userId);
      } else {
        if (redirect) {
          this._router.navigate([redirect]);
          return;
        }
        if (businessId && userId) {
          this.setBusinessAndUser(businessId, userId);
          return;
        }

        if ((!businessIdStore || businessId) && roles.length == 0 && userId) {
          this._router.navigate(['/signup']);
          return;
        }
      }
    });
  }

  private setBusinessAndUser(businessId: number, userId: string): void {
    this._businessFacade.setBusinessId(businessId);
    this._businessFacade.setUserId(userId);
  }

  initializeBusinessAndUser(): void {
    this._auth0.idTokenClaims$
      .pipe(
        filter((token) => !!token),
        distinctUntilChanged(),
      )
      .subscribe((token) => {
        this.handleTokenClaims(token);
        this._auth0.user$.subscribe((user: any) => {
          const businessId = this._businessFacade.getCurrentBusinessId();
          if (user?.sub) {
            this._businessFacade.setUserId(user?.sub || undefined);
            this._roleFacade.roles$.subscribe((roles: any) => {
              if (roles.length == 0) {
                this.checkAndUpdateUserIfEmailVerified(user?.sub || undefined);
              }
              if (
                !businessId &&
                !roles.includes(Roles.ADMIN.toLocaleLowerCase())
              ) {
                this._businessService
                  .getBusinessId(user?.sub)
                  .subscribe((res: BusinessUserResponse) => {
                    this._businessFacade.setBusinessId(res.businessId || 0);
                  });
              }
            });
          }
          this.initializeWithData(token['sub']);
        });
      });
  }

  checkAndUpdateUserIfEmailVerified(userId: string): void {
    this._businessClient
      .checkIfUserEmailVerified(userId || '')
      .subscribe((response) => {
        if (response && this._businessFacade.getCurrentBusinessId() !== 0) {
          this._roleFacade.roles$.subscribe((roles: any) => {
            if (roles.length == 0) {
              this._businessFacade.setEmailVerified(true);
              this._businessClient
                .updateBusinessUserOnVerification(userId)
                .subscribe({
                  next: (response: any) => {
                    this.logoutAndClean();
                  },
                  error: (error) => {
                    console.log('Error updating user', error);
                  },
                });
            }
          });
        } else {
          this._router.navigate(['/signup']);
        }
        this._businessService.getBusinessId(userId).subscribe({
          next: (res) => {
            this.setBusinessAndUser(res.businessId || 0, userId);
          },
          error: (error) => {
            this._roleFacade.roles$.subscribe((roles: any) => {
              if (
                !roles.includes(Roles.ADMIN.toLocaleUpperCase()) &&
                !this._businessFacade.checkIsVerified()
              ) {
                this._router.navigate(['/signup']);
              } else {
                throw error;
              }
            });
          },
        });
      });
  }

  logoutAndClean() {
    this._auth0.logout({
      logoutParams: { returnTo: document.location.origin },
    });
    sessionStorage.clear();
    this._businessFacade.clearUserAndBusinessId();
    localStorage.removeItem('user');
    localStorage.removeItem('isDrawerExpanded');
    localStorage.removeItem('userIdStore');
    localStorage.removeItem('emailVerified');
  }

  public ngOnDestroy(): void {
    this._destroy$.next(null);
    this._destroy$.complete();
  }
}
