<mat-sidenav #sideDrawer mode="over" position="end" class="p-1">
  <div class="grid-container">

    <div class="grid-title pl-2">
      <h2>{{ 'sidebarHelp.title' | translate }}</h2>
      <button
        mat-icon-button
        style="float: right; font-size: 24px"
        (click)="closeSideDrawer()"
      >
        <i class="fa-solid fa-x closeIcon"></i>
      </button>
    </div>

    <div class="grid-item pl-2 pt-3">
      <button
        mat-stroked-button
        color="primary"
        class="help-button"
        (click)="redirectToDocumentation()"
      >
        <i class="fa-solid fa-book pr-2"></i> {{ 'sidebarHelp.browsDocumentation' | translate }}
      </button>
    </div>

    <div class="grid-item pl-2 pt-3">
      <button
        mat-stroked-button
        color="primary"
        class="help-button"
        (click)="openChatWithSupport()"
      >
        <i class="fa-solid fa-messages-question"></i> {{ 'sidebarHelp.chatWithSupport' | translate }}
      </button>
    </div>
  </div>
</mat-sidenav>
