import { getUserId, RoleFacade } from '@adminpanels/shared-ui';
import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  AddressRequestModel,
  BusinessesClient,
  BusinessTypeCategory,
  BusinessTypeDto,
  BusinessTypesClient,
  CountriesClient,
  CountryDto,
  CreateBusinessRequestModel,
  CreateBusinessResponseModel,
} from 'libs/api-clients/orderadmin-api-client/src/lib/api-client';
import {
  REQUIRED_BUSINESS_TYPES,
  Roles,
  SIGN_UP_COUNTRIES,
} from '../../main/main.data';
import { AuthService } from '@auth0/auth0-angular';
import BusinessFacade from '../business.facade';
import { SnackbarService } from './../../../../../../../libs/shared-ui/src/lib/core/services/snack-bar.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {
  bussinesId: number = 0;
  signUpForm: FormGroup;
  businessForm: FormGroup;
  currentStep: number = 1;
  countries: CountryDto[] = [];
  businessTypes: BusinessTypeDto[] = [];
  subCategories: BusinessTypeCategory[] = [];

  constructor(
    private readonly fb: FormBuilder,
    private readonly countriesClient: CountriesClient,
    private readonly businessTypesClient: BusinessTypesClient,
    private readonly _businessClient: BusinessesClient,
    private readonly _auth0Service: AuthService,
    private readonly _businessFacade: BusinessFacade,
    private readonly _snackbarService: SnackbarService,
    private readonly _translate: TranslateService,
    private readonly _router: Router,
    private readonly _roleFacade: RoleFacade,
  ) {
    this.countriesClient.getAll().subscribe((countries) => {
      this.countries = countries
        .filter(
          (country) =>
            country.name &&
            SIGN_UP_COUNTRIES.includes(country.name?.toLowerCase()),
        )
        .sort((a, b) => {
          const aName = a.name?.toLowerCase();
          const bName = b.name?.toLowerCase();
          if (aName?.toLowerCase() === 'switzerland') return -1;
          if (bName?.toLowerCase() === 'switzerland') return 1;
          return 0;
        });
    });

    this.businessTypesClient.getAll().subscribe((businessTypes) => {
      this.businessTypes = businessTypes;
      this.subCategories = businessTypes
        .map((types: any) => (types.subCategories ? types.subCategories : []))
        .reduce((acc, val) => acc.concat(val), []);
    });

    this.signUpForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.pattern('^[+]?\\d*$')]],
    });

    this.businessForm = this.fb.group({
      businessTypeId: ['', Validators.required],
      businessCategoryId: ['', this.businessCategoryValidator()],
      businessName: ['', Validators.required],
      street: ['', Validators.required],
      postalCode: ['', Validators.required],
      city: ['', Validators.required],
      countryId: ['', Validators.required],
    });
  }

  get showForm() {
    let isAdmin = false;
    this._roleFacade.roles$.subscribe((roles: any) => {
      if (roles.includes(Roles.ADMIN.toLocaleLowerCase())) {
        isAdmin = true;
      }
    });
    return this._businessFacade.getCurrentBusinessId() === 0 && !isAdmin;
  }
  ngOnInit(): void {
    this._businessFacade.businessId$.data$.subscribe((businessId) => {
      if (businessId && this._businessFacade.checkIsVerified()) {
        this.bussinesId = businessId;
        this._router.navigate(['/overview']);
      }
    });
    this._businessFacade.userId$.data$.subscribe((userId) => {
      if (userId) {
        this._businessClient
          .checkIfUserEmailVerified(userId)
          .subscribe((response) => {
            if (response && this._businessFacade.getCurrentBusinessId() !== 0) {
              this._businessFacade.setEmailVerified(true);
              this._businessClient
                .updateBusinessUserOnVerification(userId)
                .subscribe({
                  next: (response: any) => {
                    this._auth0Service.logout({
                      logoutParams: { returnTo: document.location.origin },
                    });
                    sessionStorage.clear();
                    this._businessFacade.clearUserAndBusinessId();
                    localStorage.removeItem('user');
                    localStorage.removeItem('isDrawerExpanded');
                    localStorage.removeItem('userIdStore');
                    localStorage.removeItem('emailVerified');
                    this._router.navigate(['/overview']);
                  },
                  error: (error) => {
                    console.log('Error updating user', error);
                  },
                });
            }
          });
      }
    });
    this.businessForm.get('businessTypeId')?.valueChanges.subscribe(() => {
      this.businessForm.get('businessCategoryId')?.updateValueAndValidity();
    });
    const isVerified = this._businessFacade.checkIsVerified();
    if (!isVerified && !this.showForm) {
      this._translate.get('Signup.VerifyEmail').subscribe((message) => {
        this._snackbarService.trigger(message, 'x', {
          panelClass: 'error-snackbar',
        });
      });
    }
  }

  businessCategoryValidator() {
    return (control: AbstractControl) => {
      const businessTypeId = this.businessForm?.get('businessTypeId')?.value;
      const selectedBusinessType = this.businessTypes.find(
        (b: any) => b.businessTypeId === businessTypeId,
      );
      if (
        selectedBusinessType?.name &&
        REQUIRED_BUSINESS_TYPES.includes(
          selectedBusinessType?.name.toLowerCase(),
        ) &&
        !control.value
      ) {
        this.businessForm.get('businessCategoryId')?.markAsTouched();
        return { required: true };
      }
      return null;
    };
  }

  onSubmit(): void {
    if (this.currentStep === 1 && this.signUpForm.valid) {
      this.currentStep = 2; // Move to the next section
    } else if (this.currentStep === 2 && this.businessForm.valid) {
      this.finishRegistrationForUser();
    }
  }

  finishRegistrationForUser() {
    const userId = getUserId();
    const personalInfo = this.signUpForm.value;
    const businessInfo = this.businessForm.value;
    const requestModel: CreateBusinessRequestModel = {
      firstName: personalInfo.firstName,
      lastName: personalInfo.lastName,
      email: personalInfo.email,
      currency: undefined,
      phoneNumber: personalInfo.phoneNumber,
      businessTypeId: businessInfo.businessTypeId,
      businessTypeCategoryId: businessInfo.businessCategoryId || undefined,
      businessName: businessInfo.businessName,
      address: {
        street: businessInfo.street,
        postalCode: businessInfo.postalCode,
        city: businessInfo.city,
        countryId: businessInfo.countryId,
      } as AddressRequestModel,
      password: undefined,
    } as CreateBusinessRequestModel;

    this._businessClient
      .createBusinessOnSignup(requestModel, userId)
      .subscribe({
        next: (response: CreateBusinessResponseModel) => {
          if (response.businessId) {
            this._businessFacade.setBusinessId(response.businessId);
          }
        },
        error: (error: any) => {
          this._translate.get('Signup.Rety').subscribe((message) => {
            this._snackbarService.trigger(message, 'x', {
              panelClass: 'error-snackbar',
            });
          });
        },
      });
  }

  resendVerificationMail() {
    this._businessClient.resendVerificationEmail(getUserId()).subscribe({
      next: (response: any) => {
        this.logoutAndClean();
      },
      error: (error: any) => {
        this._translate.get('Verify.Error').subscribe((message) => {
          this._snackbarService.trigger(message, 'x', {
            panelClass: 'error-snackbar',
          });
        });
      },
    });
  }

  finishUserVerification(userId: string) {
    this._businessFacade.setEmailVerified(true);
    this._businessClient.updateBusinessUserOnVerification(userId).subscribe({
      next: (response: any) => {
        this.logoutAndClean();
        this._router.navigate(['/overview']);
      },
      error: (error) => {
        console.log('Error updating user', error);
      },
    });
  }

  logoutAndClean() {
    this._auth0Service.logout({
      logoutParams: { returnTo: document.location.origin },
    });
    sessionStorage.clear();
    this._businessFacade.clearUserAndBusinessId();
    localStorage.removeItem('user');
    localStorage.removeItem('isDrawerExpanded');
    localStorage.removeItem('userIdStore');
    localStorage.removeItem('emailVerified');
  }
}
