import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { InputCounterModule, SharedUiModule } from '@adminpanels/shared-ui';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { NoAccessComponent } from './modules/no-access/no-access.component';
import { CreateBusinessComponent } from './modules/business/create-business/create-business.component';
import { NotFoundComponent } from './modules/not-found/not-found.component';
import { AuthModule } from '@auth0/auth0-angular';
import { environment } from '../environments/environment';
import { RequestInterceptor } from './app.interceptor';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { API_BASE_URL } from '@adminpanels/api-clients/orderadmin-api-client';
import { SignUpComponent } from './modules/business/sigup-business/sign-up.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    NoAccessComponent,
    NotFoundComponent,
    CreateBusinessComponent,
    SignUpComponent,
  ],
  imports: [
    SharedUiModule,
    AppRoutingModule,
    InputCounterModule,
    TranslateModule.forRoot({
      defaultLanguage: 'de',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    AuthModule.forRoot({
      domain: environment.AUTH0_DOMAIN,
      clientId: environment.AUTH0_CLIENTID,
      useRefreshTokens: true,
      cacheLocation: 'localstorage',
      authorizationParams: {
        redirect_uri: window.location.origin,
        // Request this audience at user authentication time
        audience: environment.AUTH0_AUDIENCE,
        // Request this scope at user authentication time
        scope: 'openid read:current_user offline_access ',
      },
      // Specify configuration for the interceptor
      httpInterceptor: {
        allowedList: [
          {
            // Match any request that starts 'https://dev-mexdubsh7cvlq1b8.us.auth0.com/api/v2/' (note the asterisk)
            uri:
              `https://` +
              environment.AUTH0_DOMAIN +
              environment.AUTH0_API_PATH +
              '*',
            tokenOptions: {
              authorizationParams: {
                // The attached token should target this audience
                audience:
                  'https://' +
                  environment.AUTH0_DOMAIN +
                  environment.AUTH0_API_PATH,

                // The attached token should have these scopes
                scope: ' openid read:current_user offline_access',
              },
            },
          },
          {
            // Match any request that starts 'https://dev-mexdubsh7cvlq1b8.us.auth0.com/api/v2/' (note the asterisk)
            uri: `https://${environment.AUTH0_PRE_PATH}adsmanager-api.jamatu.com/*`,
            tokenOptions: {
              authorizationParams: {
                // The attached token should target this audience
                audience: `https://${environment.AUTH0_PRE_PATH}orderadmin-api.jamatu.com/`,

                // The attached token should have these scopes
                scope: ' openid read:current_user offline_access',
              },
            },
          },
          {
            // Match any request that starts 'https://dev-mexdubsh7cvlq1b8.us.auth0.com/api/v2/' (note the asterisk)
            uri: `https://localhost*`,
            tokenOptions: {
              authorizationParams: {
                // The attached token should target this audience
                audience: `https://${environment.AUTH0_PRE_PATH}orderadmin-api.jamatu.com/`,

                // The attached token should have these scopes
                scope: ' openid read:current_user offline_access',
              },
            },
          },
          {
            // Match any request that starts 'https://dev-mexdubsh7cvlq1b8.us.auth0.com/api/v2/' (note the asterisk)
            uri: `https://${environment.AUTH0_PRE_PATH}orderadmin-api.jamatu.com/*`,
            tokenOptions: {
              authorizationParams: {
                // The attached token should target this audience
                audience: `https://${environment.AUTH0_PRE_PATH}orderadmin-api.jamatu.com/`,

                // The attached token should have these scopes
                scope: ' openid read:current_user offline_access',
              },
            },
          },
          {
            // Match any request that starts 'https://dev-mexdubsh7cvlq1b8.us.auth0.com/api/v2/' (note the asterisk)
            uri: `https://${environment.AUTH0_PRE_PATH}orderadmin-service.jamatu.com/*`,
            tokenOptions: {
              authorizationParams: {
                // The attached token should target this audience
                audience: `https://${environment.AUTH0_PRE_PATH}orderadmin-api.jamatu.com/`,

                // The attached token should have these scopes
                scope: ' openid read:current_user offline_access',
              },
            },
          },
        ],
      },
    }),
  ],
  exports: [TranslateModule],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'fill' },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    {
      provide: API_BASE_URL,
      useValue: environment.BASE_API_URL,
    },
    provideAnimationsAsync(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
