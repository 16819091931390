import { BottomNavItem, NavItem } from '@adminpanels/shared-ui';

export enum Roles {
  ADMIN = 'Jamatu-Admin',
  USER = 'Jamatu-User',
  WAITER = 'Jamatu-Waiter',
  MANAGEMENT = 'Business-Management',
  OWNER = 'Business-Owner',
}

export const NAV_ITEMS: NavItem[] = [
  {
    text: 'Überblick',
    link: 'overview',
    iconClass: 'fa-duotone fa-objects-column py-1',
    requiredRole: [Roles.ADMIN, Roles.OWNER],
  },
  // {
  //   text: 'Bestellungen',
  //   link: 'orders',
  //   iconClass: 'fa-duotone fa-basket-shopping-simple',
  // },
  {
    text: 'Kunden',
    link: 'customers',
    iconClass: 'fa-duotone fa-images-user py-1',
    requiredRole: [Roles.ADMIN, Roles.OWNER],
  },
  {
    text: 'Menü',
    link: 'menu',
    iconClass: 'fa-duotone fa-plate-utensils py-1',
    requiredRole: [Roles.ADMIN, Roles.OWNER],
  },
  {
    text: 'Module',
    link: 'modules',
    iconClass: 'fa-duotone fa-puzzle-piece-simple py-1',
    requiredRole: [Roles.ADMIN, Roles.OWNER],
  },
  {
    text: 'Einstellungen',
    link: 'settings',
    iconClass: 'fa-duotone fa-sliders-simple py-1',
    requiredRole: [Roles.ADMIN, Roles.OWNER],
  },
  {
    text: 'WaiterApp',
    link: 'waiterapp',
    requiredRole: [Roles.WAITER, Roles.ADMIN, Roles.OWNER],
    openNewTab: true,
    key: 'waiterapp-url',
    iconClass: 'fas fa-glass-citrus',
  },
  {
    text: 'global.orderDashboardLabel',
    link: 'orders-dashboard',
    openNewTab: true,
    key: 'order-dashboard-url',
    iconClass: 'fa-duotone fa-link-horizontal',
    requiredRole: [Roles.ADMIN, Roles.OWNER],
  },
];

export const BOTTOM_NAV_ITEMS: BottomNavItem[] = [];

export const SIGN_UP_COUNTRIES: string[] = [
  'switzerland',
  'germany',
  'austria',
];

export const REQUIRED_BUSINESS_TYPES: string[] = ['hotel', 'restaurant'];
