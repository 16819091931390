import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CustomInputType } from '@adminpanels/shared-ui';
import { Country } from '@adminpanels/api-clients/orderadmin-api-client';

@Component({
  selector: 'orderadmin-frontend-custom-input',
  templateUrl: './custom-input.component.html',
  styleUrls: ['./custom-input.component.scss'],
})
export class CustomInputComponent {
  @Input() label = '';
  @Input() public control!: FormControl;
  @Input() type: CustomInputType = 'input';
  @Input() isDisabled = true;
  @Input() data: Country[] = [];
}
