<!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
<div
  class="fixed inset-0 flex z-40 md:hidden"
  role="dialog"
  [attr.aria-modal]="true"
  *ngIf="isVisible"
>
  <div class="fixed inset-0 bg-gray-600 bg-opacity-75" inert></div>

  <div class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
    <div class="absolute top-0 right-0 -mr-12 pt-2">
      <button
        type="button"
        class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
      >
        <span class="sr-only">Close sidebar</span>
        <svg
          class="h-6 w-6 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          inert
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>

    <div class="flex-shrink-0 flex items-center justify-center px-4">
      <img
        class="h-8 w-auto"
        src="./assets/images/logo.svg"
        alt="Jamatu logo"
      />
    </div>
    <div class="mt-5 flex-1 h-0 overflow-y-auto">
      <nav class="px-2 space-y-1">
        <span
          [routerLink]="item.link"
          class="group flex text-sm rounded-md font-medium py-2 px-2 item-center"
          *ngFor="let item of navItems"
          [ngClass]="navItemClasses(item.link)"
        >
          <img
            *ngIf="item.iconClass"
            alt="side nav item"
            height="24"
            width="24"
          />
          <span class="ml-3">{{ item.text }}</span>
        </span>
      </nav>
    </div>
  </div>

  <div class="flex-shrink-0 w-14" inert>
    <!-- Dummy element to force sidebar to shrink to fit close icon -->
  </div>
</div>
