import { DeliveryZone } from '@adminpanels/api-clients/orderadmin-api-client';

export class DeliveryZoneViewModel extends DeliveryZone {
  public isUpdated = false;

  constructor(data?: DeliveryZone) {
    super();
    if (data) {
      Object.assign(this, data);
    }
  }
}
