<div class="sign-up-container">
  <mat-card *ngIf="currentStep === 1 && showForm" class="custom-card">
    <div class="header">
      <div>
        <div
          class="flex items-center justify-center px-4 me-auto logo-container"
        >
          <h1 class="text-xl font-bold me-2">JamOrder</h1>
          <img
            class="h-8 w-auto logo"
            src="./assets/images/logo_icon.svg"
            alt="Jamatu logo"
          />
        </div>
        <p class="mt-2 text-center">A product of Jamatu</p>
      </div>

      <h1>Welcome</h1>
      <p>
        Let's customize your account, so that we can offer you the best
        experience with JamOrder.
      </p>
    </div>

    <form [formGroup]="signUpForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label for="firstName" class="custom-label">First name</label>
        <mat-form-field appearance="outline">
          <input matInput formControlName="firstName" required />
        </mat-form-field>
      </div>

      <div class="form-group">
        <label for="lastName" class="custom-label">Last name</label>
        <mat-form-field appearance="outline">
          <input matInput formControlName="lastName" required />
        </mat-form-field>
      </div>

      <div class="form-group">
        <label for="email" class="custom-label">Email address</label>
        <mat-form-field appearance="outline">
          <input matInput formControlName="email" type="email" required />
          <mat-error
            *ngIf="
              signUpForm?.get('email')?.invalid &&
              signUpForm?.get('email')?.touched
            "
          >
            Please enter a valid email address.
          </mat-error>
        </mat-form-field>
      </div>

      <div class="form-group">
        <label for="phoneNumber" class="custom-label"
          >Phone number (Optional)</label
        >
        <mat-form-field appearance="outline">
          <input matInput formControlName="phoneNumber" type="tel" />
        </mat-form-field>
        <mat-error *ngIf="signUpForm.get('phoneNumber')?.hasError('pattern')">
          Please enter a valid phone number.
        </mat-error>
      </div>

      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="signUpForm?.invalid"
      >
        Continue
      </button>
    </form>
  </mat-card>

  <mat-card *ngIf="currentStep === 2 && showForm" class="custom-card">
    <h1>Your Business</h1>
    <p>
      With this form you will create your business and access JamOrder to manage
      it.
    </p>

    <form [formGroup]="businessForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label class="custom-label">Business type</label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="businessTypeId" required>
            <mat-option
              *ngFor="let businessType of businessTypes"
              [value]="businessType.businessTypeId"
            >
              {{ businessType.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="form-group">
        <label class="custom-label">Business category</label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="businessCategoryId">
            <mat-option
              *ngFor="let businessCategory of subCategories"
              [value]="businessCategory.businessTypeCategoryId"
            >
              {{ businessCategory.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="businessForm.invalid">
            Business type is required.
          </mat-error>
        </mat-form-field>
      </div>

      <div class="form-group">
        <label class="custom-label">Business name</label>
        <mat-form-field appearance="outline">
          <input matInput formControlName="businessName" required />
        </mat-form-field>
      </div>

      <div class="form-group">
        <label class="custom-label">Street & nr.</label>
        <mat-form-field appearance="outline">
          <input matInput formControlName="street" required />
        </mat-form-field>
      </div>

      <div class="form-row">
        <div class="form-group">
          <label for="postalCode" class="custom-label">Postal code</label>
          <mat-form-field appearance="outline">
            <input matInput formControlName="postalCode" required />
          </mat-form-field>
        </div>
        <div class="form-group">
          <label for="city" class="custom-label">City</label>
          <mat-form-field appearance="outline">
            <input matInput formControlName="city" required />
          </mat-form-field>
        </div>
      </div>

      <div class="form-group">
        <label class="custom-label">Country</label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="countryId" required>
            <mat-option
              *ngFor="let country of countries"
              [value]="country.countryId"
            >
              {{ country.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="businessForm.invalid"
      >
        Sign up
      </button>
    </form>
  </mat-card>
  <mat-card *ngIf="!showForm" class="custom-card-verification">
    <div class="content-wrapper">
      <p class="card-text">{{ 'Signup.Confirm' | translate }}</p>
      <button
        mat-raised-button
        (click)="resendVerificationMail()"
        color="primary"
        class="resend-button"
      >
        {{ 'Signup.Resend' | translate }}
      </button>
    </div>
  </mat-card>
</div>
