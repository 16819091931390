<div class="header">
  <h2>
    {{ 'sidebarHelp.contactWhatsappModalTitle' | translate }}
  </h2>
  <button class="close-icon" (click)="onClose()">
    <i class="fa-solid fa-x"></i>
  </button>
</div>
<hr />
<p>
  {{ 'sidebarHelp.contactWhatsappModalText' | translate }}
</p>
<div class="body">
  <div class="button-container">
    <button mat-raised-button color="primary" (click)="openWhatsApp()">
      {{ 'sidebarHelp.contactWhatsappModalButton' | translate }}
    </button>
  </div>
  <div class="qr-code-container">
    <img [src]="qrCodeImageUrl" class="qr-code-image" alt="WhatsApp QR Code" />
  </div>
</div>
