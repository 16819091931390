import {
  IMinimumOrderValueDto,
  MinimumOrderValueDto,
} from '@adminpanels/api-clients/orderadmin-api-client';

export class MinimumOrderValueViewModel extends MinimumOrderValueDto {
  isUpdated = false;

  constructor(data?: IMinimumOrderValueDto) {
    super(data);
  }

  override toJSON(data?: any) {
    data = super.toJSON(data);
    // Remove isUpdated before serialization
    delete data.isUpdated;
    return data;
  }
}
