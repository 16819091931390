import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'orderadmin-frontend-chat-support-dialog',
  templateUrl: './chat-support-dialog.component.html',
  styleUrl: './chat-support-dialog.component.scss'
})
export class ChatSupportDialogComponent {
  qrCodeImageUrl = './assets/images/whatsapp-qr-code.png';

  constructor(public dialogRef: MatDialogRef<ChatSupportDialogComponent>) {}

  onClose(): void {
    this.dialogRef.close();
  }

  openWhatsApp(): void {
    window.open('https://wa.me/message/YP6EVAAIPIM5L1', '_blank');
  }
}
