import { Injectable } from '@angular/core';
import { Menu, MenuProducts } from './models/menus.model';
import { Observable } from 'rxjs';
import {
  AssignProductsRequest,
  BaseMenuRequestModelDto,
  CreateMenuAvailabilityRequestDto,
  MenuDto,
  MenuRequestModel,
  MenusClient,
  OrderingProductMenuRequest,
  ProductsClient,
  SetMenuSpecificPriceRequest,
} from '@adminpanels/api-clients/orderadmin-api-client';

type menuId = string;

@Injectable({
  providedIn: 'root',
})
export class MenusService {
  constructor(
    private readonly _menusClient: MenusClient,
    private readonly _productsClient: ProductsClient,
  ) {}

  private readonly menu: Map<menuId, Menu> = new Map();
  private readonly menuType: Map<menuId, MenuProducts> = new Map();

  /**
   * value
   */
  public get value(): Menu[] {
    return Array.from(this.menu.values());
  }

  /**
   * size
   */
  public get size(): number {
    return this.menu.size;
  }

  public getMenus(
    businessId: number,
    isAllRelatedDataNeeded = true,
  ): Observable<MenuDto[]> {
    return this._menusClient.getAll(businessId, isAllRelatedDataNeeded);
  }

  public getActiveMenus(
    businessId: number,
    isAllRelatedDataNeeded = true,
  ): Observable<MenuDto[]> {
    return this._menusClient.getActiveMenus(businessId, isAllRelatedDataNeeded);
  }

  public patchMenuProductOrder(
    payload: OrderingProductMenuRequest,
  ): Observable<any> {
    return this._productsClient.orderingProductMenu(payload);
  }

  /**
   * createMenus
   */
  public createMenus(payload: MenuRequestModel): Observable<MenuRequestModel> {
    return this._menusClient.create(payload);
  }

  public updateMenu(payload: BaseMenuRequestModelDto) {
    return this._menusClient.update(payload);
  }

  public setProductPrice(
    menuId: number,
    productId: number,
    priceRequest: SetMenuSpecificPriceRequest,
  ) {
    return this._menusClient.setMenuSpecificPrice(
      priceRequest,
      menuId,
      productId,
    );
  }

  public setProductVariantPrice(
    menuId: number,
    productId: number,
    variantId: number,
    priceRequest: SetMenuSpecificPriceRequest,
  ) {
    return this._menusClient.setMenuSpecificVariantPrice(
      priceRequest,
      menuId,
      productId,
      variantId,
    );
  }

  public assignProduct(
    payload: AssignProductsRequest,
    menuId: number,
  ): Observable<void> {
    return this._menusClient.assignProducts(payload, menuId);
  }

  /**
   * Update status of Menu
   */
  public updateStautsMenu(status: boolean, menuId: number): Observable<void> {
    return this._menusClient.updateMenuStatus(status, menuId);
  }

  public saveMenuAvailability(
    payload: CreateMenuAvailabilityRequestDto,
  ): Observable<void> {
    return this._menusClient.createMenuAvailability(payload);
  }

  public updateMenuAvailability(
    payload: CreateMenuAvailabilityRequestDto,
  ): Observable<void> {
    return this._menusClient.updateMenuAvailability(payload);
  }
}
