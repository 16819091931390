import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'orderadmin-frontend-no-access',
  templateUrl: './no-access.component.html',
  styleUrls: ['./no-access.component.scss'],
})
export class NoAccessComponent implements OnInit {
  constructor(private readonly _titleService: Title) {}

  public ngOnInit(): void {
    this._titleService.setTitle('No Access');
  }
}
