import { Component, Input } from '@angular/core';
import { NavItem } from '@adminpanels/shared-ui';
import { Router } from '@angular/router';
import { ACTIVE_CLASSES, INACTIVE_CLASSES } from '../side-nav/side-nav.data';

@Component({
  selector: 'app-mobile-nav',
  templateUrl: './mobile-nav.component.html',
  styleUrls: ['./mobile-nav.component.scss'],
})
export class MobileNavComponent {
  @Input() public isVisible = true;
  @Input() public navItems: NavItem[] = [];

  constructor(private readonly _router: Router) {}

  public navItemClasses(link: string): string[] {
    return this._isActive(link) ? ACTIVE_CLASSES : INACTIVE_CLASSES;
  }

  private _isActive(link: string): boolean {
    return (
      this._router.url?.replace('/', '')?.toLowerCase() === link?.toLowerCase()
    );
  }
}
